.backy5 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #ff7ad6, #cccdf1)
}

.backy2 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #fff, #fff);
}

.backy26 {
    background-color: #1b064c
}

.filter-butter {
    filter: invert(99%) sepia(68%) saturate(1050%) hue-rotate(330deg) brightness(106%) contrast(111%);

}


.backy3 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #e1eaf4, #e1eaf4);
}

.backy7 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #e1eaf4, rgb(212, 212, 212));
}


.backy49 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #7b61ff, #e1eaf4);
}


.buttonSpecial {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 24px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #7b61ff;
    box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
    -webkit-transition: .2s;
    transition: .2s;
    font-family: Grifter, sans-serif;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px;
    text-decoration: none;
}

.headerColor{
        background-image: linear-gradient(140deg, #9b87ff, #fcd065);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
}


.boxSpecial {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    padding: 24px 30px;
    border-radius: 8px;
    background-color: #000;
    box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
    -webkit-transition: .2s;
    transition: .2s;
    font-family: Grifter, sans-serif;
    color: #fff;
    font-size: 17px;
    font-weight: 700;

    letter-spacing: .5px;
    text-decoration: none;
}

.buttonSpecial2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 24px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: white;
    box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
    -webkit-transition: .2s;
    transition: .2s;
    font-family: Grifter, sans-serif;
    color: black;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px;
    text-decoration: none;
}