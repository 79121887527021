.backy {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #fbe8de, #fbe8de)
}

.backy2 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #fff, #fff);
}


.backy3 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #e1eaf4, #e1eaf4);
}


.buttonSpecial {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 24px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #000;
    box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
    -webkit-transition: .2s;
    transition: .2s;
    font-family: Grifter, sans-serif;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px;
    text-decoration: none;
}