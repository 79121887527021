.backy {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #fbe8de, #fbe8de)
}

.backy2 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #fff, #fff);
}

.pattern2{
    background-image:radial-gradient(circle farthest-corner at 50% 50%, #F5F5F5, #F5F5F5 21%, rgba(246, 245, 250, 0) 80%), url("./pattern.png");
    background-color: #F5F5F5;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}


.backy3 {
    background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), linear-gradient(180deg, #e1eaf4, #e1eaf4);
}


.buttonSpecial {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 24px 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    background-color: #000;
    box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
    -webkit-transition: .2s;
    transition: .2s;
    font-family: Grifter, sans-serif;
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .5px;
    text-decoration: none;
}